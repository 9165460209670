<template>
  <div class="flex flex-col border lg:border-2">
    <Bar
      :title="offer.title.label"
      :context="offer.title.context"
      :target="offer.title.target"
    />
    <div class="font-black text-g7-blue lg:font-semibold">
      <div
        class="m-2 mt-4 flex flex-row text-xs sm:text-sm md:text-base lg:m-4"
      >
        <NuxtLink
          :to="offer.action_button.context"
          :target="offer.action_button.target"
        >
          <CloudinaryImg
            :public-id="offer.image.public_id"
            :steps="[200, 400]"
            class="hidden border object-cover lg:block lg:h-48 lg:w-60 lg:border-2 2xl:h-60 2xl:w-96"
          />
        </NuxtLink>
        <div class="flex w-full flex-col lg:ml-5">
          <div class="relative flex flex-row">
            <NuxtLink
              :to="offer.action_button.context"
              :target="offer.action_button.target"
            >
              <CloudinaryImg
                :public-id="offer.image.public_id"
                :steps="[200, 400]"
                class="order-first h-[4.5rem] w-28 border object-cover sm:h-32 sm:w-60 lg:hidden"
              />
            </NuxtLink>
            <div class="ml-2 w-full leading-none sm:ml-5 lg:ml-0">
              <table>
                <tbody>
                  <tr>
                    <td class="whitespace-nowrap align-top lg:w-28">
                      {{ offer.cruise_line_name.label }}:
                    </td>
                    <td
                      class="w-full max-w-0 truncate pl-2 sm:w-auto sm:max-w-none lg:pl-4"
                      v-html="offer.cruise_line_name.value"
                    />
                  </tr>
                  <tr>
                    <td class="whitespace-nowrap align-top lg:w-28">
                      {{ offer.ship_name.label }}:
                    </td>
                    <td
                      class="w-full max-w-0 truncate pl-2 sm:w-auto sm:max-w-none lg:pl-4"
                      v-html="offer.ship_name.value"
                    />
                  </tr>
                  <tr>
                    <td class="whitespace-nowrap align-top lg:w-28">
                      {{ offer.cruise_region_name.label }}:
                    </td>
                    <td
                      class="w-full max-w-0 truncate pl-2 sm:w-auto sm:max-w-none lg:pl-4"
                      v-html="offer.cruise_region_name.value"
                    />
                  </tr>

                  <tr>
                    <td class="whitespace-nowrap align-top lg:w-28">
                      {{ offer.duration.label }}:
                    </td>
                    <td class="pl-2 lg:pl-4" v-html="offer.duration.value" />
                  </tr>
                  <tr>
                    <td class="whitespace-nowrap align-top lg:w-28">
                      {{ offer.period.label }}:
                    </td>
                    <td
                      class="w-full max-w-0 truncate pl-2 sm:w-auto sm:max-w-none lg:pl-4"
                      v-html="offer.period.value"
                    />
                  </tr>
                  <tr>
                    <td class="hidden lg:table-column"></td>
                    <td
                      colspan="2"
                      class="w-full truncate p-2 sm:w-auto sm:max-w-none lg:pl-4"
                    >
                      <CloudinaryImg
                        :public-id="offer.cruise_line_logo.public_id"
                        :steps="[200, 400]"
                        class="h-6 lg:h-8"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="absolute right-0 hidden xl:block">
              <table class="border lg:border-2">
                <tbody>
                  <tr class="border lg:border-2">
                    <td
                      v-for="(cabinType, index) in offer.cabin_type_lafs"
                      :key="index"
                      class="border px-1.5 text-center lg:border-2"
                    >
                      {{ cabinType.label }}
                    </td>
                  </tr>
                  <tr class="border lg:border-2">
                    <td
                      v-for="(cabinType, index) in offer.cabin_type_lafs"
                      :key="index"
                      class="border px-1.5 text-center lg:border-2"
                      v-html="
                        (cabinType.laf.amount.prefix != undefined
                          ? cabinType.laf.amount.prefix + ' <br> '
                          : '') + cabinType.laf.amount.formatted
                      "
                    />
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="flex justify-between">
            <table>
              <tbody class="my-2 leading-none lg:my-0">
                <tr>
                  <td class="whitespace-nowrap align-top lg:w-28">
                    {{ offer.itinerary.label }}:
                  </td>
                  <td class="pl-2 lg:pl-4" v-html="offer.itinerary.value" />
                </tr>
              </tbody>
            </table>
            <div>
              <div
                v-if="showBookmark"
                class="mx-1 my-2 ml-2 flex size-8 cursor-pointer items-center justify-center rounded-full !bg-red-500 lg:mx-2 lg:my-0 lg:size-10"
                @click="removeBookmark"
              >
                <LoadingSpinner v-if="pending" color="white" size="xs" />
                <IconsHeart
                  v-if="!pending"
                  class="size-5 fill-white lg:size-6"
                />
              </div>
            </div>
          </div>
          <div class="flex h-full justify-between">
            <div class="flex flex-col justify-end">
              <div class="flex">
                <div
                  class="mr-1 size-6 rounded-full bg-teal sm:size-8 lg:mr-2 lg:size-10"
                >
                  <IconsShip
                    class="size-6 fill-white p-1 sm:size-8 sm:p-1.5 lg:size-10 lg:p-2"
                  />
                </div>
                <div
                  v-if="offer.includes_hotel"
                  class="mr-1 size-6 rounded-full bg-teal sm:size-8 lg:mr-2 lg:size-10"
                >
                  <IconsBed
                    class="size-6 fill-white p-1 sm:size-8 sm:p-1.5 lg:size-10 lg:p-2"
                  />
                </div>
                <div
                  v-if="offer.includes_flight"
                  class="mr-1 size-6 rounded-full bg-teal sm:size-8 lg:mr-2 lg:size-10"
                >
                  <IconsPlane
                    class="size-6 fill-white p-1 sm:size-8 sm:p-1.5 lg:size-10 lg:p-2"
                  />
                </div>
              </div>
            </div>

            <div class="flex flex-col justify-end">
              <div>
                <span
                  class="font-bold text-g7-blue decoration-transparent lg:text-lg"
                >
                  {{ offer.cruise_laf.amount.context }}
                  {{ offer.cruise_laf.amount.prefix }}
                </span>
                <span
                  class="text-xl text-g7-blue sm:text-2xl lg:text-4xl"
                  v-html="' ' + offer.cruise_laf.amount.formatted"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr class="mx-2 lg:mx-5 lg:border-2" />

      <div
        class="m-2 flex flex-col items-center justify-between gap-2 sm:flex-row sm:gap-0 lg:m-4"
      >
        <div :class="{ 'hidden sm:block': !offer.admonitions }">
          <div v-if="offer.admonitions" class="flex flex-row">
            <div
              v-if="offer.admonitions.show_icon"
              class="mr-1 size-6 rounded-full bg-orange-500 sm:size-8 lg:size-10"
            >
              <IconsExclamation
                class="size-6 fill-white p-1 sm:size-8 sm:p-1.5 lg:size-10 lg:p-2"
              />
            </div>
            <div
              v-if="offer.admonitions.admonitions"
              class="ml-1 text-xs sm:text-sm md:text-base lg:ml-2"
            >
              <p
                v-for="(admonition, index) in offer.admonitions.admonitions"
                :key="index"
                v-html="admonition"
              />
            </div>
          </div>
        </div>
        <G7Button
          class="p-2 text-xs sm:text-sm"
          :color="ctaOrange ? 'orange' : 'blue'"
          :label="offer.action_button.label"
          :to="offer.action_button.context"
          target="_blank"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useToast } from "vue-toastification";
import type { CruiseListOfferType } from "~~/types/cruise";
import type { BookmarksResponse } from "~/types/response";

const props = defineProps<{
  ctaOrange: boolean;
  offer: CruiseListOfferType;
  showBookmark: boolean;
}>();

const emit = defineEmits(["refresh-offers"]);

const toast = useToast();
const pending = ref(false);

function removeBookmark() {
  pending.value = true;
  useGo7seasClient<BookmarksResponse>("/catalog/mygo7seas/bookmarks", {
    method: "POST",
    body: {
      cruises: [props.offer.display_id.value],
    },
  })
    .then(() => {
      toast.success("Reise entfernt");
    })
    .finally(() => {
      pending.value = false;
      emit("refresh-offers");
    });
}
</script>
